import React, { useState, useEffect, useCallback } from 'react';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    description: '',
    category: 'misc',
  });

  const [cafes, setCafes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit] = useState(15); // Number of items per page
  const [offset, setOffset] = useState(0); // Starting point of data to fetch

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Automatically update Slug when Name is changed
    if (name === 'name') {
      const formattedSlug = value
        .toLowerCase()
        .replace(/\s+/g, '') // Replace spaces with underscores
        .replace(/[^a-z0-9_]/g, ''); // Remove special characters, keep alphanumeric and underscores
      setFormData((prevState) => ({
        ...prevState,
        slug: formattedSlug,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `https://api.thewatercooler.cafe/cafes?slug=${encodeURIComponent(
      formData.slug
    )}&name=${encodeURIComponent(formData.name)}&description=${encodeURIComponent(
      formData.description
    )}&category=${formData.category}&is_private=0&status=active`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        fetchCafes(); // Refresh the list of cafes
        setFormData({
          // Reset the form fields
          name: '',
          slug: '',
          description: '',
          category: 'misc',
        });
        setIsModalOpen(false); // Close the modal on successful submission
      } else {
        console.error('Error submitting form', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  // Use useCallback to memoize fetchCafes and avoid recreating it on every render
  const fetchCafes = useCallback(async (currentOffset = 0) => {
    try {
      const response = await fetch(`https://api.thewatercooler.cafe/cafes?limit=${limit}&offset=${currentOffset}`);
      const data = await response.json();

      if (data.success && Array.isArray(data.results)) {
        setCafes(data.results);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching cafes:', error);
    }
  }, [limit]);

  useEffect(() => {
    fetchCafes(offset); 
  }, [fetchCafes, offset]);

  const handleNext = () => {
    setOffset(offset + limit);
  };

  const handlePrevious = () => {
    setOffset(Math.max(0, offset - limit));
  };

  return (
    <div className="bg-gray-900 p-8 min-h-screen flex flex-col rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold leading-7 text-white">Created Cafes</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          New Cafe
        </button>
      </div>

      <div id="create_cafe" className="mt-8 flex-grow overflow-auto">
      <div className="">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Slug
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Private
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {cafes.map((cafe) => (
                  <tr key={cafe.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                      {cafe.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                      /c/{cafe.slug}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                      {cafe.description}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                      {cafe.category}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                      {cafe.is_private ? 'Yes' : 'No'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                      {new Date(cafe.created_at).toLocaleDateString()}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                      {cafe.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div className="flex justify-end mt-4">
        <button
          onClick={handlePrevious}
          disabled={offset === 0}
          className="mr-2 px-4 py-2 bg-gray-700 text-white rounded disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className="px-4 py-2 bg-gray-700 text-white rounded"
        >
          Next
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black bg-opacity-75"></div>
          <div className="bg-gray-800 rounded-lg p-6 z-50 w-full max-w-lg mx-auto">
            <h2 className="text-xl font-semibold leading-7 text-white mb-4">Create Cafe</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="slug" className="block text-sm font-medium leading-6 text-white">
                    Slug
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        /c/
                      </span>
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        value={formData.slug}
                        onChange={handleChange}
                        required
                        className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-white">
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="category" className="block text-sm font-medium leading-6 text-white">
                    Category
                  </label>
                  <div className="mt-2">
                    <select
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-[#2a3441] py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    >
                        <option value="animals">Animals</option>
                        <option value="entertainment">Entertainment</option>
                        <option value="gaming">Gaming</option>
                        <option value="hobbies">Hobbies</option>
                        <option value="humor">Humor</option>
                        <option value="culture">Culture</option>
                        <option value="literature">Literature</option>
                        <option value="misc">Misc</option>
                        <option value="nfsw">NSFW</option>
                        <option value="politics">Politics</option>
                        <option value="sports">Sports</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormComponent;
