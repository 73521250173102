import React, { useRef, useState, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';

const EmojiPickerWrapper = ({ chatInput }) => {
  const emojiPickerRef = useRef(null);
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

  const onEmojiClick = (emojiData, event) => {
    if (chatInput.current) {
      chatInput.current.value += emojiData.emoji;
    }
  };

  const handleClickOutside = (event) => {
    if (event.type !== 'mousedown') {
      return;
    }

    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !event.target.closest('button')
    ) {
      setIsEmojiPickerVisible(false);
    }
  };

  useEffect(() => {
    if (isEmojiPickerVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEmojiPickerVisible]);

  return (
    <div>
      <button className="pr-6 border-r-4 border-gray-600 bg-gray-600 bounce emoji-button" onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)} tabIndex="-1">
        {isEmojiPickerVisible ? '😮' : '😊'}
      </button>
      {isEmojiPickerVisible && 
        <div className="absolute bottom-16 right-0" ref={emojiPickerRef}>
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            onEmojiClick={onEmojiClick}
            skinTonesDisabled="true"
            theme="dark" />
        </div>}
    </div>
  );
};

export default EmojiPickerWrapper;
