import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import Modal from './Modal';

const ImageUpload = ({ acceptedFiles = [], showUpload = true }) => {
  const fileInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleMessageClick = (message) => {    
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const S3 = useMemo(() => new S3Client({
    endpoint: `https://${process.env.REACT_APP_ACCOUNT_ID}.r2.cloudflarestorage.com`,
    credentials: {
      accessKeyId: process.env.REACT_APP_R2_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_R2_SECRET_ACCESS_KEY,
    },
    region: "auto",
  }), []);

  const uploadFiles = useCallback(async (files) => {
    console.log('Uploading files...'  + files);
    alert('Uploading files...'  + files);
    const uploadPromises = files.map(async (file) => {

      alert('Uploading promise...'  + file);
      try {

        alert('Try an upload...'  + file);

        const url = await getSignedUrl(
          S3,
          new PutObjectCommand({
            Bucket: process.env.REACT_APP_R2_BUCKET_NAME,
            Key: file.name,
          }),
          {
            expiresIn: 60 * 60 * 24 * 7, // 7 days
          }
        );

        alert('this is the url: ' + url) ; 

        await fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        return URL.createObjectURL(file);

      } catch (error) {
        throw new Error(`Failed to upload ${file.name}`);

      }
    });


    try {
      const uploadedFiles = await Promise.all(uploadPromises);
      setUploadedImages((prev) => [...prev, ...uploadedFiles]);
      setUploadStatus('success');
      handleMessageClick('Your upload was successful');
    } catch (error) {
      setUploadStatus('fail');
      handleMessageClick('Oh no! Your upload failed');
    }
  }, [S3]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      uploadFiles(acceptedFiles);
    }
  }, [acceptedFiles, uploadFiles]);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) {
      return;
    }
    await uploadFiles(files);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage(''); // Reset message
    setUploadedImages([]); // Clear uploaded images
  };

  if(showUpload === true) {

    return (
      <div>
        <span
          onClick={triggerFileInput}
          style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
          className="text-3xl text-grey border-r-4 border-gray-600 bg-gray-600 p-2"
        >
          <svg
            className="h-6 w-6 block bg-gray-500 hover:bg-gray-400 cursor-pointer rounded-xl"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M16 10c0 .553-.048 1-.601 1H11v4.399c0 .552-.447.601-1 .601-.553 0-1-.049-1-.601V11H4.601C4.049 11 4 10.553 4 10c0-.553.049-1 .601-1H9V4.601C9 4.048 9.447 4 10 4c.553 0 1 .048 1 .601V9h4.399c.553 0 .601.447.601 1z"
              fill="#FFFFFF"
            />
          </svg>
        </span>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          multiple
          onChange={handleFileChange}
        />
        
        <Modal icon={uploadStatus} isOpen={isModalOpen} onClose={() => closeModal()}>
          <h3 className="text-base font-semibold leading-6 text-white" id="modal-title">{modalMessage}</h3>
          <div id="uploaded-thumbnail-previews" className="mt-2 flex space-x-2 flex-wrap">
            {uploadedImages.length > 0 && uploadedImages.map((src, index) => (
              <img key={index} src={src} alt={`uploaded preview ${index + 1}`} className="object-cover w-12 h-12 rounded-md" />
            ))}
          </div>
        </Modal>
      </div>
    );
  }

  return null;
};

export default ImageUpload;