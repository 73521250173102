import React from 'react';

const Sidebar = () => {

  return (
    <div>
        <div>
            <h1 className="text-xl font-semibold">Jennifer Lawrence</h1>
            <p className="text-gray-300">A place for your Jennifer Lawrence Fappening</p>

            <div className="pt-2 flex items-center justify-between">
                <div className="text-white font-bold">448K Members</div>
                <div className="text-green-200 font-bold">155 Online</div>
                <div className="text-p-900 font-bold">Top 1%</div>
            </div>
            <ul className="pt-10 text-sm">
                <li>All material must relate to Jennifer Lawrence. Content promoting products in any way is not allowed.</li>
                <li>Keep it classy and respectful</li>
                <li>Titles should provide context. Posts such as IG must include context and at least the month/year.</li>
                <li>Celebs must be 18+ at time of photo.</li>
            </ul>
        </div>

        <div className="grid grid-cols-5 justify-end gap-4">
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 1" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 12" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 13" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 14" className="h-full w-full" /></div>
        
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 15" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 16" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 17" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 18" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 19" className="h-full w-full" /></div>

        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 20" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 21" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 22" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 23" className="h-full w-full" /></div>
        <div className=" h-12 w-12 rounded-md object-cover"><img src="https://cdn.thewatercooler.cafe/empty-photo.png" alt="Default 24" className="h-full w-full" /></div>

        </div>

    </div>
  );
};

export default Sidebar;