import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmojiPickerWrapper from './EmojiPickerWrapper';
import ImageUpload from './ImageUpload';
import useWebSocket from './useWebSocket';  // Import the custom WebSocket hook

const ChatRoom = ({ initialUsername }) => {
  const { slug } = useParams(); // Get the slug from the URL

  const [currentRoomname, setCurrentRoomname] = useState(''); // State to track the current roomname
  const chatul = useRef(null); // Ref to hold the chat log messages
  // const usernameRef = useRef(initialUsername); // Username reference

  // Function to add a chat message to the DOM
  const addChatMessage = (name, text) => {
    const li = document.createElement("li");
    li.classList.add('group', 'relative', 'rounded-lg', 'pb-2');

    const time = document.createElement("span");
    time.className = 'text-xxs text-slate-500';
    time.innerText = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const username = document.createElement("span");
    username.className = 'pl-2 text-sm font-bold text-purple-600';
    username.innerText = name;

    const user_message = document.createElement("div");
    user_message.className = 'pl-12 text-sm';
    user_message.innerHTML = text;

    li.appendChild(time);
    li.appendChild(username);
    li.appendChild(user_message);

    if (chatul.current) {
      chatul.current.appendChild(li);
      chatul.current.scrollTop = chatul.current.scrollHeight; // Auto-scroll to the bottom
    }
  };

  // WebSocket message handler
  const onMessage = (data) => {
    if (data.message) {
      addChatMessage(data.name || "Moderator", data.message); // Use the addChatMessage function
    }
  };

  

  // Optional: WebSocket error handler
  const onError = (event) => {
    console.error('WebSocket error:', event);
  };

  // Optional: WebSocket open handler
  const onOpen = (ws) => {
    console.log("WebSocket connection opened");
    // Send initial user information
    // ws.send(JSON.stringify({ name: usernameRef.current }));
  };

  // Construct the WebSocket URL based on the room slug
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const wsUrl = `${protocol}://chat-engine.markbratanov.workers.dev/api/room/${encodeURIComponent(slug || 'lobby')}/websocket`;


  // Use the WebSocket hook without storing the returned `ws` as it's not needed here
  useWebSocket(wsUrl, onMessage, onError, onOpen);

  useEffect(() => {
    // Clear chat log when switching rooms
    if (currentRoomname !== slug) {
      setCurrentRoomname(slug);

      // Clear the chat log
      if (chatul.current) {
        chatul.current.innerHTML = '';
      }
    }
  }, [slug, currentRoomname]);

  return (
    <div className="relative flex h-full flex-col justify-end">
      <div id="chatlog" className="overflow-auto bg-gray-800 pl-2 h-full">
        <ul ref={chatul} className="space-y-1"></ul> {/* Chat messages are rendered here */}
      </div>
      <div className="flex rounded-lg overflow-hidden w-full bg-gray-600">          
        <ImageUpload />
        <form id="chatroom" action="/form-action" className="flex items-center w-full m-0 p-0" autoComplete="off">
          <input id="chat-input" type="text" className="w-full px-4 bg-gray-600 focus:outline-none focus:ring-0" />
        </form>
        <EmojiPickerWrapper />
      </div>
    </div>
  );
};

export default ChatRoom;