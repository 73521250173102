import { useState, useEffect, useRef, useCallback } from 'react';

const useWebSocket = (url, onMessage, onError, onOpen) => {
  const [ws, setWs] = useState(null);
  const reconnectRef = useRef(false);

  const connectWebSocket = useCallback(() => {
    const socket = new WebSocket(url);

    socket.onopen = (event) => {
      setWs(socket);
      reconnectRef.current = false;
      onOpen && onOpen(event);
    };

    socket.onmessage = (event) => {
      onMessage && onMessage(JSON.parse(event.data));
    };

    socket.onerror = (event) => {
      onError && onError(event);
      reconnectRef.current = true;
    };

    socket.onclose = (event) => {
      if (reconnectRef.current) {
        setTimeout(() => connectWebSocket(), 5000); // Reconnect after 5 seconds
      }
    };

    return () => {
      socket.close();
    };
  }, [url, onMessage, onError, onOpen]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [connectWebSocket, ws]);

  return ws;
};

export default useWebSocket;