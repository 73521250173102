import React, { useState } from 'react';
import Menu from './components/Menu';
import ChatRoom from './components/ChatRoom';
import CreateCafe from './components/CreateCafe'; // Ensure this component is imported correctly
import ImageUpload from './components/ImageUpload';
import { useDropzone } from 'react-dropzone';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';


function App() {

  console.log("App.js is reloading");

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [showChatRoom, setShowChatRoom] = useState(true); // State to toggle between ChatRoom and CreateCafe

  const username = "user" + Math.floor(Math.random() * 10000);
  
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 10) {
      alert('You can only upload a maximum of 10 files');
      return;
    }
    setAcceptedFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 10,
  });

  return (
    <Router>
      <div {...getRootProps({ className: 'dropzone m-0 h-screen w-full bg-gray-800 p-0 text-gray-200' })}>
        <input {...getInputProps()} />
        {isDragActive && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="text-white text-xl">Drop your images here...</div>
          </div>
        )}
        <header className="fixed left-0 right-0 top-0 flex items-center bg-gray-800">
          <img src="https://cdn.thewatercooler.cafe/thumbnail.webp" alt="Logo" className="h-16" /> The Water Cooler Cafe

          <div className="ml-auto p-4">
            <button type="button" className="relative rounded-full bg-gray-600 p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="absolute -inset-1.5"></span>
              <span className="sr-only">View notifications</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
              </svg>
            </button>
          </div>
        </header>

        <div className="flex h-full pt-16">

          <div className="relative w-1/5 overflow-auto rounded-lg shadow bg-gray-700 mb-3">
            <Menu onAddCafesClick={() => setShowChatRoom(false)} /> {/* Pass the toggle function as a prop */}
          </div>

          <div className="w-3/5 m-3">
            <div className="relative flex h-full flex-col justify-end">

            <Routes>
                <Route path="/c/:slug" element={<ChatRoom initialUsername={username} />} /> {/* Pass slug to ChatRoom */}
                <Route path="/" element={showChatRoom ? <ChatRoom initialUsername={username} /> : <CreateCafe />} /> {/* Default route */}
            </Routes>

            {/* {showChatRoom ? (
                <ChatRoom initialUsername={username} initialRoomname={roomname} />
              ) : (
                <CreateCafe /> // Show CreateCafe when ChatRoom is hidden
              )}           */}
            </div>
          </div>

          <div className="w-1/5 p-2">
            <Sidebar />
            <ImageUpload acceptedFiles={acceptedFiles} showUpload={false} />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;