import React from 'react';

const Modal = ({ isOpen, onClose, children, icon}) => {
  if (!isOpen) return null;

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

          <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-gray-500 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">

                {icon === 'success' && (
                  <div className="mx-auto flex h-60 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <img className="h-60px" src="https://cdn.thewatercooler.cafe/water-glass-icon_24911-115733-removebg-preview.png" alt="success icon" />
                  </div>
                )}
                {icon === 'fail' && (
                  <div className="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-red-300 sm:mx-0 sm:h-10 sm:w-10">
                    <img className="h-10" src="https://cdn.thewatercooler.cafe/error-water-glass_24911-115784-removebg-preview.png" alt="failure icon" />
                  </div>
                )}

                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left text-white">
                  {children}
                </div>
              </div>
            </div>
            <div className="bg-gray-700 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button onClick={onClose} type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-purple-700 px-3 py-2 text-sm font-semibold text-gray-200 hover:bg-purple-600 sm:mt-0 sm:w-auto">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;