import React, { useState, useEffect } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const Menu = ({ onAddCafesClick }) => {
  const [inputValue, setInputValue] = useState('');
  const [cafes, setCafes] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate


  // Fetch all cafes once when the component mounts
  useEffect(() => {
    const fetchCafes = async () => {
      try {
        const response = await axios.get('https://api.thewatercooler.cafe/cafes/search'); // Adjust the endpoint URL as needed
        setCafes(response.data.results); // Store all cafes data
      } catch (error) {
        console.error('Error fetching cafes:', error);
      }
    };

    fetchCafes();
  }, []);

  // Filter suggestions based on the input value
  const handleInputValueChange = ({ inputValue }) => {
    setInputValue(inputValue || ''); // Set to empty string if undefined
    const filtered = cafes
      .filter(cafe => cafe.slug.toLowerCase().includes((inputValue || '').toLowerCase()))
      .slice(0, 5);
    setSuggestions(filtered);
  };

  // Handle selection or click on a suggestion
  const handleSelect = (selectedItem) => {
    if (selectedItem) {
      navigate(`/c/${selectedItem.slug}`); // Redirect to the cafe's page using its slug
    }
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    items: suggestions,
    inputValue,
    onInputValueChange: handleInputValueChange,
    onSelectedItemChange: ({ selectedItem }) => handleSelect(selectedItem), // Call handleSelect on item selection
    itemToString: (item) => (item ? item.slug : 'No results'), // Ensure itemToString returns a string
  });

  return (
    <div className=''>
      <div className="mt-3 ml-2 mr-2 flex items-center bg-gray-800 rounded-lg px-4 py-2" {...getInputProps()}>
        <svg
          className="h-6 w-6 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.9 14.32a8 8 0 111.414-1.414l4.387 4.387a1 1 0 01-1.415 1.415l-4.387-4.387zM8 14a6 6 0 100-12 6 6 0 000 12z"
            clipRule="evenodd"
          />
        </svg>
        <input
          type="text"
          placeholder="Search for a cafe"
          className="bg-transparent border-none text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-0 ml-3 w-full"
          {...getInputProps()}
        />
      </div>

      <ul
        {...getMenuProps()}
        className={`bg-gray-800 rounded-lg p-2 absolute z-10 w-11/12 z-10 mt-2 ml-2 ${!isOpen ? 'hidden' : ''}`}
      >
        {isOpen &&
          suggestions.map((item, index) => (
            <li
              key={item.id || index} // Ensure each key is unique and reliable
              {...getItemProps({ item, index })}
              className={`text-white p-2 cursor-pointer hover:bg-gray-700 rounded ${
                highlightedIndex === index ? 'bg-gray-700' : ''
              }`}
            >
              {item.slug}
            </li>
          ))}
      </ul>
      
      {/* <ul
        {...getMenuProps()}
        className={`bg-gray-800 rounded-lg p-2 ${!isOpen ? 'hidden' : ''} absolute w-11/12 z-10 mt-2 ml-2`}
      >
        {isOpen &&
          suggestions.map((item, index) => (
            <li
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
              className={`text-white p-2 cursor-pointer hover:bg-gray-700 rounded ${
                highlightedIndex === index ? 'bg-gray-700' : ''
              }`}
            >
              {item}
            </li>
          ))}
      </ul> */}

  <nav className='m-5'>
    <ul className="space-y-3">
      <li className="flex items-center space-x-3 p-2 rounded-md hover:bg-purple-800 hover:text-purple-600">
        <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM8 11H6v2h2v-2zm6 0h-2v2h2v-2z" />
        </svg>
        <a href="#home" className="text-base text-gray-300">Home</a>
      </li>
      <li className="flex items-center space-x-3 p-2 rounded-md hover:bg-purple-800 hover:text-purple-600">
        <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM8 11H6v2h2v-2zm6 0h-2v2h2v-2z" />
        </svg>
        <a href="#popular" className="text-base text-gray-300">Popular</a>
      </li>
    </ul>

    <div className="mt-6">
      <h3 className="text-gray-500 text-sm uppercase mb-3">Recent</h3>
      <ul className="space-y-3">
        <li className="flex items-center space-x-3 p-2 rounded-md hover:bg-purple-800 hover:text-purple-600">
          <span className="h-6 w-6 rounded-full bg-red-500 inline-block"></span>
          <a href="#formula1" className="text-base text-gray-300">c/formula1</a>
        </li>
      </ul>
    </div>

    <div className="mt-6">
      <h3 className="text-gray-500 text-sm uppercase mb-3">Categories</h3>
      <ul className="space-y-1">
        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#hobbies" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Hobbies</span>
          </a>
        </li>
        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#entertainment" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Entertainment</span>
          </a>
        </li>

        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#Humor" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Humor</span>
          </a>
        </li>

        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#culture" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Culture</span>
          </a>
        </li>

        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#NSFW" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">NSFW</span>
          </a>
        </li>


        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#Misc" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Misc</span>
          </a>
        </li>



        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#sports" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Sports</span>
          </a>
        </li>


        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#gaming" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Gaming</span>
          </a>
        </li>

        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#animals" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Animals</span>
          </a>
        </li>


        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#Literature" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Literature</span>
          </a>
        </li>




        <li className="cursor-pointer rounded-md hover:bg-gray-500 hover:text-white">
          <a href="#Politics" className="flex items-center space-x-3 p-2">
            <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z" />
            </svg>
            <span className="text-base text-gray-300">Politics</span>
          </a>
        </li>

      </ul>
    </div>
  </nav>

  <div className="absolute bottom-0 w-full">
    <div className="text-gray-500 text-xs flex justify-center items-center">
      <button onClick={onAddCafesClick} className="rounded-md bg-white/10 px-2.5 w-11/12 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20 mb-6">
        Manage Cafes
      </button>
    </div>
  </div>
</div>
  );
};

export default Menu;